@tailwind base;
@tailwind components;
@tailwind utilities;
.text-main {
    @apply text-blue-900
}

/*.text-blue-900{*/
/*    @apply text-gray-950*/
/*}*/
/*.bg-blue-900{*/
/*    @apply bg-gray-950*/
/*}*/
/*.hover\:bg-blue-800:hover{*/
/*    @apply hover:bg-gray-600*/
/*}*/
.tox-editor-header {
    z-index: 0!important;
}

.react-confirm-alert-overlay {
    z-index: 9999999; /* Đặt z-index lớn hơn các phần tử khác */
}